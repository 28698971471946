<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div class="sticky">
      <div class="p-0 md:p-5 bg-white" :class="classObj + `${!sidebar ? ' w-full' : ''}` ">

        <div v-if="showCloseCta" class="flex items-center justify-between relative p-3">
          <button @click="closeIframeView" class="cursor-pointer p-3 border-none bg-transparent">
            <feather-icon icon="ArrowLeftIcon" class="text-primary2" />
          </button>
          <template v-if="showTitle">
            <h1 class="text-xl font-semibold"> Resource Hub </h1>
          </template>
          <template v-if="isIframe && showCart">
            <cart-drop-down />
          </template>
        </div>
        <vs-navbar v-if="!isIframe" class="vx-navbar navbar-custom navbar-skelton shop-nav" :color="navbarColorLocal" :class="{'p-0':isShopPages,textColor, 'px-0':(isCreateOrUpdateConsentPage || isCreateOrUpdateAftercareDocs)}">
          <!-- SM - OPEN SIDEBAR BUTTON -->
          <div class="flex justify-between items-center w-full padder z-40 bg-white" style="height:46px;" :class="{'px-5 pt-4':isShopPages, 'xl:justify-end':!(isShopPages || isCreateOrUpdateConsentPage || isCreateOrUpdateAftercareDocs), 'pt-8 lg:pt-0 md:pt-0':(isCreateOrUpdateConsentPage || isCreateOrUpdateAftercareDocs)}">
            <div class=" flex">
              <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2 pl-0  text-primary2 " icon="MenuIcon" @click.stop="showSidebar" v-if="sidebar" :class="{'pl-5':(isCreateOrUpdateAftercareDocs || isCreateOrUpdateConsentPage)}" />
                <div
                  v-if="isShopPages"
                  class="md:hidden vx-logo cursor-pointer flex items-center"
                  @click="redirectToShopHome"
                >
                  <img
                    class="mr-2 fill-current text-primary"
                    style="width: 28px"
                    :src="require('../../../../../public/Group-543.svg')"
                  />
                  <img
                    :src="require('../../../../../public/Group-544.svg')"
                    width="100px"
                  />
                </div>
            </div>
            <div v-if="isShopPages" class="hidden flex-col ease-in duration-300 md:flex mr-4">
              <ClinicSwap title="Delivery to" />
            </div>
            <SearchInput v-if="isShopPages" class="hidden md:flex w-full" />
            <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->
            <!-- <i18n /> -->
            <!-- <search-bar /> -->
            <!-- <cart-drop-down /> -->
            <div class="flex flex-row justify-items-end items-center" :class="{'pr-5':(isCreateOrUpdateAftercareDocs || isCreateOrUpdateConsentPage)}">
              <whats-new
                :class="{ 'ml-1': isShopPages }"></whats-new>

              <template v-if="!isIframe">
                <cart-drop-down v-if="showCart"/>
              </template>
              <!-- <vs-button color="primary" type="border" @click="closeIframeView" v-if="showCloseCta">Close Screen</vs-button> -->
              <!-- <nurse-notification-drop-down /> -->
              <nurse-profile-drop-down v-if="showUserDropdown"/>
            </div>
          </div>
          <div v-if="isCreateOrUpdateConsentPage" class="w-full">
            <vs-divider v-if="!consentFormSubmissionCompleted"></vs-divider>
            <ConsentFormNavbar />
          </div>

          <!-- aftercare form -->
          <div v-if="isCreateOrUpdateAftercareDocs" class="w-full">
            <vs-divider v-if="!aftercareFormSubmissionCompleted"></vs-divider>
            <AftercareNavbar />
          </div>
          <!-- end aftercare form -->

          <div class="flex items-center w-full md:hidden px-5 justify-between bg-white py-2" v-if="isShopPages"  >
            <Navbar v-if="isShopPages" class="md:hidden border-0 pt-3"/>
            <SearchInput class="w-full justify-end flex" />
          </div>
          <vs-divider v-if="isShopPages" class="hidden md:block" />
          <Navbar v-if="isShopPages" class="hidden md:block border-0 pt-3"/>
          <div  id="deliver_to" class="px-5 py-3 flex md:hidden absolute justify-between w-full items-center gap-2 box-border z-20 " style="background: #F9F9F9; top:95px;" v-if="isShopPages" >
              <ClinicSwap title="Deliver to:" class="px-5"/>
          </div>
          <NurseAlertBanner id="alert_banner_mobile" class="absolute md:static z-0 items-center top-alert" v-if="isShopPages "/>
        </vs-navbar>
      </div>
    </div>
</template>

<script>
// import Bookmarks from "../components/Bookmarks.vue";
// import I18n from "../components/I18n.vue";
// import SearchBar from "../components/SearchBar.vue";
// import CartDropDown from "../components/CartDropDown.vue";
import NurseNotificationDropDown from "../components/nurse/NurseNotificationDropDown.vue";
import NurseProfileDropDown from "../components/nurse/NurseProfileDropDown.vue";
import CartDropDown from "../components/org-owner/ClinicCartDropDown.vue";
import ClinicSwap from "../../../../views/components/shop/clinic.vue";
import SearchInput from "../../../../views/components/shop/Search.vue";
import { mapActions } from "vuex";
import SideBarCategories from "../../../../views/components/shop/SideBarCategories.vue"
import Navbar from "../../../../views/components/shop/Navbar.vue";
import NurseAlertBanner from "../../alert-banner/nurseAlertBanner.vue"
import ConsentFormNavbar from "../../../../views/components/consent-forms/Navbar.vue";
import AftercareNavbar from "../../../../views/components/aftercare/Navbar.vue";
import WhatsNew from "@/layouts/components/navbar/components/WhatsNew.vue";

export default {
  name: "TheNurseNavbarVertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff"
    },
    sidebar: {
      type: Boolean,
      default: true
    },
    showUserDropdown: {
      type: Boolean,
      default: true
    },
    showCloseCta:{
      type: Boolean,
      default: false
    }
  },
  components: {
    // Bookmarks,
    // I18n,
    // SearchBar,
    CartDropDown,
    NurseNotificationDropDown,
    NurseProfileDropDown,
    ClinicSwap,
    SearchInput,
    SideBarCategories,
    Navbar,
    NurseAlertBanner,
    ConsentFormNavbar,
    AftercareNavbar,
    WhatsNew
  },
  data() {
    return {
      showCart: false,
      isIframe: false,
      showTitle: false,
    }
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark")
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    // eslint-disable-next-line vue/return-in-computed-property
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
    },
    isShopPages() {
      return `${this.$route.path}`.includes("/shop") || `${this.$route.path}`.includes("/product") || `${this.$route.path}`.includes("/checkout")
    },
    isCreateOrUpdateConsentPage() {
      if (!`${this.$route.path}`.includes("/consent-forms")) return false;
      return `${this.$route.path}`.includes("/create") || `${this.$route.path}`.includes("/details")
    },
    consentFormSubmissionCompleted() {
      return this.$store.getters["consentForms/isSubmissionCompleted"];
    },
    isCreateOrUpdateAftercareDocs() {
      if (!`${this.$route.path}`.includes("/aftercare")) return false;
      return `${this.$route.path}`.includes("/create") || `${this.$route.path}`.includes("/details");
    },
    aftercareFormSubmissionCompleted() {
      return this.$store.getters["aftercare/isSubmissionCompleted"]
    },
  },
  methods: {
     // Set mapActions fetchUserPermission
    ...mapActions("admin", ["fetchUserPermission"]),
    closeIframeView() {
      const windowTemp = window;
      windowTemp.webkit.messageHandlers.jsMessageHandler.postMessage(
        'close-screen'
      );
    },
    async isShopEnabled() {
      // Set organizationId from localStorage
      const organizationId = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );

      // response
      const { data: permissions } = await this.fetchUserPermission({
        userId: this.$store.state.AppActiveUser.id,
        organizationId,
      })
      // Verify permission name is Shop
      if (permissions.some(obj => obj.permission.name === 'Shop') && !this.showCloseCta) {
        this.showCart = true
      }

    },

  showSidebar() {
    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
  },
  redirectToShopHome(){
      const userType = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

        let routerName = "";
        if (role === "Org Owner") {
            routerName = "OrgOwnerOrderCenter";
        } else {
            if (userType === "doctor") {
                routerName = "DoctorOrderCenter";
            } else {
                routerName = "NurseOrderCenter";
            }
        }
        this.$router.push({
            name: routerName});
        },
  },
  mounted(){
    this.isShopEnabled()
    const iframeView = localStorage.getItem("iframe-view");
    if(iframeView.length) {
      const closeCta = JSON.parse(iframeView);
      this.isIframe = closeCta.closeCta;  
    }
    
    this.showTitle = this.$route.name === 'resources';
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
  //   "$store.state.showStore": {
  //     handler(val) {
  //       console.log('showcart nurse', val)
  //       this.showCart = val;
  //     },
  //     immediate:true
  //   }
  "isShopPages": {
      handler(val){
        if(!val){
            var element = document.getElementsByClassName("router-content");
            element[0].classList.remove("shop-alert-margin")
            element[0].classList.remove("shop-margin")
        }
      }
    }
  },
};
</script>
<style lang="scss" scope>
#content-area {
  background-color: white;
}
.title {
  min-height: 3rem;
}
.sticky {
  position: sticky;
  top: .5rem;
  z-index: 9999;
  border-bottom: 1px solid #ececec;
}
.sticky + .content-wrapper .router-content {
  margin-top: 0 !important;
}
.home-link{
background-color: rgba(222,239,234,.34);
width: 34px;
height: 34px;
min-width: 34px;
}
.padder{
@media screen and (max-width: 768px) {
  padding: .8rem 1rem
}
}

.search-wrapper {
width: 100%;
max-width: 600px;
}

.navbar-custom {

&.shop-nav .vs-con-items {
  @apply flex-col
}

@media screen and (max-width: 768px) {
  border-radius: 0px !important;
  padding: 0px !important;
}

}

.shop-wrapper{
margin-bottom: -5rem !important;
}

.shop-wrapper-fixed-search{
margin-bottom: 5rem !important;
}

.top-alert {
@media (max-width: 768px) {
  top: 133px;
}

}

</style>
